<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", "resources", "resources") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
          <router-link
            v-if="can('MANAGE_RESOURCES', 'create')"
            :to="{
              name: 'r_add-resource',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <search
        ref="search"
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        v-on:keyup.enter="submitSearch"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_resources-search-filters'"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="resources && resources.length">
      <li
        class="clebex-item-section-item"
        :class="{
          'lead-icon-border': mode === 'selection'
        }"
        v-for="resource in resources"
        :key="resource.id"
      >
        <template v-if="mode === 'selection'">
          <div class="clebex-item-content-wrapper">
            <div class="checkbox tiny block alt">
              <input
                type="checkbox"
                :id="`resource${resource.id}`"
                name="resource"
                :selected="selectedResources.includes(resource.id)"
                :value="resource.id"
                @change="selectResource(resource)"
                :disabled="!canItem(resource, 'delete')"
              />
              <label :for="`resource${resource.id}`">
                <icon icon="#cx-app1-checkmark" />
                <span
                  class="inner-text favorite-right-info"
                  v-if="resource.all_parents && resource.all_parents.length"
                >
                  <span>
                    <span class="highlight">{{ `${resource.name}, ` }}</span>
                    {{ displayParentNames(resource.all_parents) }}
                  </span>
                </span>
                <span class="inner-text favorite-right-info" v-else>
                  {{ resource.name }}
                </span>
              </label>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="clebex-item-content-wrapper">
            <span
              class="label"
              v-if="resource.all_parents && resource.all_parents.length"
            >
              <span class="text">
                <span class="highlight">{{ `${resource.name}, ` }}</span>
                {{ displayParentNames(resource.all_parents) }}
              </span>
            </span>
            <span class="label" v-else>
              {{ resource.name }}
            </span>
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                @click="editResource(resource.id)"
              >
                <icon icon="#cx-app1-information"></icon>
              </button>
            </span>
          </div>
        </template>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{ displayLabelName("resources", "resources", "select") }}
            </button>
          </li>
          <li
            class="action"
            v-if="mode === 'selection'"
            style="margin-right: auto"
          >
            <button
              class="action-btn active"
              v-if="
                selectedResources &&
                  selectedResources.length == 1 &&
                  can('MANAGE_RESOURCES', 'create')
              "
              @click="setResourceToDuplicate"
              :disabled="
                !selectedResources || !can('MANAGE_RESOURCES', 'create')
              "
            >
              {{ displayLabelName("resources", "resources", "duplicate") }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                (selectedResources ? selectedResources.length : 0) +
                "/" +
                (resources ? resources.length : 0) +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :hide-timer="true"
      :prevent-confirm="!Boolean(duplicatedLevelName)"
      :confirm-action="duplicateResource"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="closeDuplicateModal"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("resources", "resources", "enter-new-resource-name")
        }}
      </h3>
      <section class="tiny-input">
        <div class="form-group input">
          <input
            type="text"
            v-model="duplicatedLevelName"
            @input="clearNameError"
          />
          <span
            class="error-message"
            v-if="duplicateFormSubmitted && duplicateNameError"
          >
            {{ duplicateNameError }}
          </span>
        </div>
      </section>
    </screen-modal>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal delete-modal"
      type="success"
      :confirm-action="checkDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("resources.resources.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("resources.resources.delete-action-text") }}
        <br />
        {{ displayLabelName("resources.resources.delete-action-question") }}
      </p>
    </screen-modal>
    <screen-modal
      v-if="showDeleteConfirmModal"
      :hide-timer="true"
      class="confirm-modal delete-modal"
      type="warning"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :show="showDeleteConfirmModal"
      @close="closeDeleteModals"
    >
      <h3 class="modal-title">
        {{ displayLabelName("resources.resources.delete-warning-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("resources.resources.delete-warning-message") }}
        <br />
        {{ displayLabelName("global.messages.delete-warning-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Resources",
  mixins: [helpOnlineMixin, paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      show: false,
      duplicateFormSubmitted: false,
      duplicatedLevelName: "",
      duplicateNameError: "",
      resourceToDuplicate: null,
      selectedResource: null,
      showDeleteModal: false,
      showDeleteConfirmModal: false,
      mode: "",
      search: null,
      helpSlug: "resources",
      selectedResources: []
    };
  },
  computed: {
    ...mapState("resource", [
      "resources",
      "resource",
      "resourceTypes",
      "resourceModels",
      "resourceAttributes",
      "resource",
      "resourceTranslations",
      "resourcesPaginationData",
      "resourcesLoading",
      "resourceFunctionTypes"
    ]),
    ...mapState("level", ["levels"]),
    ...mapState("searchFiltersResources", [
      "selectedTypeIds",
      "selectedFunctionTypeIds",
      "selectedLevelIds",
      "selectedModelIds",
      "selectedAttributesIds",
      "selectedCapacity",
      "searchActiveNum"
    ]),
    ...mapState("language", ["languages"]),
    routeName() {
      return this.$route.name;
    },
    deleteButtonStyle() {
      if (this.mode === "selection" && this.selectedResources.length) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    },
    selectedFilters() {
      let filters = [];
      const {
        resourceTypes,
        selectedTypeIds,
        resourceModels,
        selectedModelIds,
        resourceAttributes,
        selectedAttributesIds,
        selectedCapacity,
        resourceFunctionTypes,
        selectedFunctionTypeIds,
        levels,
        selectedLevelIds
      } = this;
      if (
        levels &&
        levels.data &&
        levels.data.length &&
        selectedLevelIds &&
        selectedLevelIds.length
      ) {
        const filtered = levels.data.filter(
          item => selectedLevelIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "level" }];
        });
      }

      if (
        resourceFunctionTypes &&
        resourceFunctionTypes.data &&
        resourceFunctionTypes.data.length &&
        selectedFunctionTypeIds &&
        selectedFunctionTypeIds.length
      ) {
        const filtered = resourceFunctionTypes.data.filter(
          item => selectedFunctionTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceFunctionType" }];
        });
      }

      if (
        resourceTypes &&
        resourceTypes.length &&
        selectedTypeIds &&
        selectedTypeIds.length
      ) {
        const filtered = resourceTypes.filter(
          item => selectedTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceType" }];
        });
      }

      if (
        resourceModels &&
        resourceModels.data &&
        resourceModels.data.length &&
        selectedModelIds &&
        selectedModelIds.length
      ) {
        const filtered = resourceModels.data.filter(
          item => selectedModelIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      if (
        resourceAttributes &&
        resourceAttributes.length &&
        selectedAttributesIds &&
        selectedAttributesIds.length
      ) {
        const filtered = resourceAttributes.filter(
          item => selectedAttributesIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceAttribute" }];
        });
      }

      if (selectedCapacity) {
        filters.push({
          id: "cap1",
          name:
            this.displayLabelName("resources", "resources", "capacity") +
            " " +
            selectedCapacity,
          type: "capacity"
        });
      }
      return filters;
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.setSearchQuery(value);
      }
    }
  },
  created() {
    this.setPaginationData(null);
    this.getResources({ model_ids: null, reset: false });
    if (!this.search && this.$route.name === "r_resource-filters") {
      this.$router.push({ name: "r_resources" });
    }
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResources",
      "getResource",
      "duplicateResourceApi",
      "deleteResource",
      "checkDeleteResource"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    ...mapActions("level", ["resetLevelsState"]),
    ...mapActions("searchFiltersResources", [
      "setSelectedTypeIds",
      "setSelectedFunctionTypeIds",
      "setSelectedLevelIds",
      "setSelectedModelIds",
      "setSelectedAttributeIds",
      "setSelectedCapacity"
    ]),
    ...mapActions("search", ["setSearchQuery"]),
    selectResource(resource) {
      if (this.selectedResources.includes(resource.id)) {
        this.selectedResources.splice(
          this.selectedResources.indexOf(resource.id),
          1
        );
      } else {
        this.selectedResources.push(resource.id);
      }
    },
    editResource(id) {
      return this.getResource({ id: id }).finally(() => {
        setTimeout(() => {
          if (this.resource && this.resource.data) {
            this.$router.push({ name: "r_edit-resource" });
          }
        }, 0);
      });
    },
    setResourceToDuplicate() {
      this.show = true;
      this.resourceToDuplicate = { id: this.selectedResources[0] };
    },
    duplicateResource() {
      this.duplicateFormSubmitted = true;
      if (!this.duplicatedLevelName) {
        this.duplicateNameError = this.displayLabelName(
          "resources",
          "resources",
          "please-enter-level-name"
        );
      } else {
        this.duplicateResourceApi({
          id: this.resourceToDuplicate.id,
          name: this.duplicatedLevelName
        })
          .then(() => {
            this.setPaginationData(null);
            this.duplicateFormSubmitted = false;
            this.duplicatedLevelName = "";
            this.duplicateNameError = "";
            this.resourceToDuplicate = null;
            this.selectedResources = [];
            this.getResources({ model_ids: null, reset: true });
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          });
      }
    },
    closeDuplicateModal() {
      this.duplicateFormSubmitted = false;
      this.show = false;
      this.duplicatedLevelName = "";
      this.resourceToDuplicate = null;
    },
    clearNameError() {
      if (this.duplicateNameError) this.duplicateNameError = "";
    },
    setMode(mode) {
      this.mode = mode;
      this.selectedResource = null;
    },
    async checkDelete() {
      let checkDeleteResource = true;
      for (var i = 0; i < this.selectedResources.length; i++) {
        var checkDel = await this.checkDeleteResource(
          this.selectedResources[i]
        );
        if (!checkDel || !checkDel.data || !checkDel.data.allowed) {
          checkDeleteResource = false;
        }
      }

      if (checkDeleteResource == true) {
        this.setPaginationData(null);
        for (var j = 0; j < this.selectedResources.length; j++) {
          await this.deleteResource(this.selectedResources[j]);
        }
        this.selectedResources = [];
      } else {
        this.showDeleteConfirmModal = true;
      }
    },
    async startDelete() {
      this.setPaginationData(null);
      for (var i = 0; i < this.selectedResources.length; i++) {
        await this.deleteResource(this.selectedResources[i]);
      }
      this.selectedResources = [];
    },
    closeDeleteModals() {
      this.showDeleteConfirmModal = false;
      this.showDeleteModal = false;
    },
    toggleSearch() {
      this.search = !this.search;

      if (this.search) {
        this.resourceListTop += 55;
      } else {
        this.resourceListTop -= 55;
      }

      if (!this.search && this.$route.name === "r_resources-search-filters") {
        this.$router.push({ name: "r_resources" });
      }
    },
    submitSearch() {
      this.setPaginationData(null);
      this.setSearchQuery(this.searchQuery);
      this.getResources();
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    },
    removeSelectedFilter(filter) {
      if (filter.model) {
        return this.setSelectedModelIds(filter.id);
      }

      if (filter.type === "resourceAttribute") {
        return this.setSelectedAttributeIds(filter.id);
      }

      if (filter.type === "resourceType") {
        return this.setSelectedTypeIds(filter.id);
      }

      if (filter.type === "resourceFunctionType") {
        return this.setSelectedFunctionTypeIds(filter.id);
      }

      if (filter.type === "level") {
        return this.setSelectedLevelIds(filter.id);
      }

      if (filter.type === "capacity") {
        return this.setSelectedCapacity(0);
      }
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.setPaginationData(null);
      this.setSearchQuery(null);
      this.getResources();
    }
  },
  beforeUnmount() {
    this.resetLevelsState();
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
